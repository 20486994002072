import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CalendarRow from './CalendarRow';
import UserItem from './UserItem';
import 'moment/locale/sv';
import { getToday } from 'components/Home/Forecast/utils';

const useStyles = makeStyles((theme) => ({
  calendar_container: {
    width: '100%',
    maxHeight: '80vh',
    height: '1000px',
    backgroundColor: 'white',
  },
  background_container: {
    marginLeft: '200px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '80px',
    },
  },
  lines_container: {
    display: 'flex',
    height: '100%',
  },
  days_container: {
    display: 'flex',
  },
  day: {
    flex: 1,
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'center',
    borderBottom: '0.5px solid #E5E5E5',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
  },
  day_subtitle: {
    fontSize: 8, fontWeight: 400, color: '#333', [theme.breakpoints.down('sm')]: {
      display: 'none',
    },

  },
  day_weekend: {
    flex: 0.15,
    fontSize: 0,
    borderBottom: 0,
    opacity: 0
  },
  today: {
    fontWeight: 700
  },
  months_container: {
    display: 'flex',
    zIndex: 2,
  },
  month: {
    textAlign: 'center',
    paddingTop: '4px',
    marginBottom: '4px',
    paddingBottom: '4px',
    // borderRight: '1px solid #AAA',
    // borderLeft: '1px solid #AAA',
    borderBottom: '0.5px solid #E5E5E5',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
  },
  line_container: {
    flex: 1,
    backgroundColor: '#FFF', //'#F9FAFB',

  },
  line_weekend_container: {
    backgroundColor: '#F9FAFB',
    flex: 0.15,

  },
  lines_outer_container: {
    position: 'absolute',
    left: 216,
    right: 16,
    top: 108,
    bottom: 0,

    [theme.breakpoints.down('sm')]: {
      left: 96,
    },
  },
  line: {
    height: '100%',
    width: '1px',
    backgroundColor: '#E5E5E5',
    flex: 1,
  },
  line_month: {
    backgroundColor: '#E8E8E8',
    width: 2,
    marginTop: -30,
    height: 'calc(100% + 30px)',
  },
  items_container: {
    width: '100%',
    zIndex: 2,
    position: 'relative',
    height: 'calc(100% - 62px)',
    paddingBottom: '8px',
    flex: 1,
  },
  users_container: {
    flex: '0 0 200px',
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 80px',
    },
  },
  content_container: {
    display: 'flex',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100% - 46px)',
    scrollbarWidth: 'none',
    "&::-webkit-scrollbar": {
      display: "none"
    },
    '-ms-overflow-style': 'none'
  },
}));

export interface CalendarItemType {
  startDate: moment.Moment;
  endDate?: moment.Moment;
  title: string;
  status?: string;
  externalSource?: string | null;
  _id?: string | null;
}

export type CalendarUserType = {
  isCurrentUser: boolean;
  id: string | null | undefined;
  name: string | null | undefined;
  currentWorkload: number | null | undefined;
  title: string | null | undefined;
  profilePicture: string | null | undefined;
  items: CalendarItemType[];
};

const Calendar = ({
  users,
  startDate,
  length,
  editItem,
  openAddModal,
  isAdmin,
  viewOnly
}: {
  users: CalendarUserType[] | null | undefined;
  startDate: moment.Moment;
  length: number;
  editItem: (item: CalendarItemType, userId: string) => void;
  openAddModal: (userId: string) => void;
  isAdmin: boolean;
  viewOnly: boolean;
}) => {
  const classes = useStyles();



  const isWeekend = (itemStartDate, offset = 0) => [6, 0].includes(moment(itemStartDate).add(offset, 'days').locale('en').weekday())
  const adjustedDayLength = (itemStartDate, offset = 0) => isWeekend(itemStartDate, offset) ? 0.15 : 1;

  const months = [{ title: moment(startDate).locale("en").format('MMMM'), length: 0 }];

  for (let i = 0; i < length; i++) {
    if (moment(startDate).add(i, 'days').date() === 1)
      months.push({
        title: moment(startDate).locale("en").add(i, 'days').format('MMMM'),
        length: adjustedDayLength(startDate, i),
      });
    else months[months.length - 1].length += adjustedDayLength(startDate, i);
  }

  return (
    <>
      <div className={classes.calendar_container}>
        <div className={classes.background_container}>
          <div className={classes.lines_outer_container}>
            <div className={classes.lines_container}>
              {[...Array(length)].map((_, i) => (
                <div
                  className={
                    classes.line_container +
                    ' ' +
                    (isWeekend(startDate, i) ? classes.line_weekend_container : '')
                  }
                >
                  {(![6, 0, 1].includes(moment(startDate).add(i, 'days').locale('en').weekday()) || moment(startDate).add(i, 'days').locale('en').date() == 1) && (<div className={`${classes.line} ${moment(startDate).add(i, 'days').locale('en').date() == 1 && classes.line_month}`} />)}
                </div>
              ))}
            </div>
          </div>
          <div className={classes.months_container}>
            {months.map((month) => (
              <div className={classes.month} style={{ flex: month.length }}>
                {month.length > 5 && month.title}
              </div>
            ))}
          </div>

          <div className={classes.days_container}>
            {[...Array(length)].map((_, i) => (
              <div className={classes.day + ' ' + ((moment(startDate).add(i, 'days').isSame(moment(getToday()))) ? (classes.today) : '') + ' ' + (isWeekend(startDate, i) ? classes.day_weekend : '')}>

                <span className={classes.day_subtitle}>{moment(startDate).locale('en').add(i, 'days').format('dd')}</span>
                {' '}
                {moment(startDate).locale('en').add(i, 'days').format('D')}

              </div>
            ))}
          </div>
        </div>
        <div className={classes.content_container}>
          <div className={classes.users_container}>
            {users?.map((user) => (
              <UserItem user={user} openAddModal={openAddModal} isAdmin={isAdmin && !viewOnly} />
            ))}
          </div>
          <div className={classes.items_container}>
            {users?.map((user) => (
              <CalendarRow user={user} startDate={startDate} length={length} edit={editItem} viewOnly={viewOnly} isAdmin={isAdmin} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Calendar;
