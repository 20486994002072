import { makeStyles } from '@material-ui/core';
import CalendarItem from './CalendarItem';
import { CalendarUserType, CalendarItemType } from './Calendar';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  calendar_row: {
    height: '50px',
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

const CalendarRow = ({
  user,
  edit,
  startDate,
  length,
  isAdmin,
  viewOnly
}: {
  user: CalendarUserType;
  edit: (item: CalendarItemType, userId: string) => void;
  startDate: moment.Moment;
  length: number;
  isAdmin: boolean;
  viewOnly: boolean;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.calendar_row}>
      {user.items.map((item) => (
        <CalendarItem
          properties={item}
          isEditable={(user.isCurrentUser || isAdmin) && !viewOnly && item.status == 'away' && !item.externalSource}
          startDate={startDate}
          length={length}
          edit={() => user.id && edit(item, user.id)}
        />
      ))}
    </div>
  );
};

export default CalendarRow;
